import React from 'react'
import { NavLink } from 'react-router-dom';

const SideNav = ({isOpen, toggleSide}) => {
  

  return (
    <>
    <nav className={isOpen? 'side show':'side'} onClick={toggleSide}>
    <NavLink className='sidelink' to='/'>Home</NavLink>     <NavLink className='sidelink' to='about'>About</NavLink>
    <NavLink className='sidelink' to='services'>Services</NavLink>
    <NavLink className='sidelink' to='contact'>Contact</NavLink>    
     </nav>
    </>
  )
}
    // <nav>
      
    // </nav>

export default SideNav