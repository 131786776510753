import React from 'react'
import pick from '../images/pick.jpg'
import {FaHandsHelping}from'react-icons/fa';
const Contact = () => {
  return (
    <main>
      <section id='section-contact'>
        <img id='contact-img' src={pick} alt="" />
        <article id='hire'>
          <FaHandsHelping id='hands'/>
          <h1>Hire us</h1>
        </article>
      </section>
    </main>
  )
}

export default Contact