import React from 'react'
import logo from '../images/micrologo.png';
import { NavLink } from 'react-router-dom';

const Branding = () => {
  return (
    <div className='branding'>
     <NavLink to='/'> <img src={logo} alt="logo" id='logo' title='home'/></NavLink>
    </div>
  )
}

export default Branding