import React, { useState } from 'react'
import Branding from './Branding';
import Navbar from './Navbar';
import SideNav from './SideNav';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSide=()=>{
setIsOpen(!isOpen) 
  }
  return (
    <>
    <header>
      <Branding/>
      <Navbar toggleSide={toggleSide}/>
      <SideNav isOpen={isOpen} toggleSide={toggleSide} />
    </header>
    
      
    </>
  )
}

export default Header