import React from 'react'
import { Link } from 'react-router-dom';
import {FaLinkedin, FaSkype, SiMicrosoftteams} from 'react-icons/fa';


const Footer = () => {
  var today= new Date().getFullYear();
  return (
    <footer>
      <div id="footer-container">
        <div className="location">
          <p className='bold'>Micro Cro d.o.o.</p> 
           <p> 178/32, Omladinskih brigada,  <br />
            Belgrade, 11000, <br />
            Serbia</p>
        </div>
     <div className="contact">
          
        <div className="social">
          
            <Link className='social-link' target='_blank' to='http://www.linkedin.com/profile/view?id=53364439&authType=NAME_SEARCH&authToken=Tvjd&locale=en_US&srchid=2883080871380224479929&srchindex=1&srchtotal=10&trk=vsrp_people_res_name&trkInfo=VSRPsearchId%3A2883080871380224479929%2CVSRPtargetId%3A53364439%2CVSRPcmpt%3Aprimary'>
            <FaLinkedin/> 
            </Link>
            <Link className='social-link' target='_blank' to='https://join.skype.com/invite/zDVVSivmRF9K'><FaSkype/></Link>
            {/* <Link className='social-link' target='_blank' to='https://join.skype.com/invite/zDVVSivmRF9K'><SiMicrosoftteams/></Link>
            */}
        </div>
        <div className='mail'>
            <p>office@microcro.com</p>
        </div>
        </div>
      </div>

      <div id="made-by">
        <small>Web Art Scepan | All rights reserved | &copy; {today}</small>  
      </div>
    </footer>
  )
}

export default Footer