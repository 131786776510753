import React from 'react'
import {NavLink} from 'react-router-dom';
import {FaBars} from 'react-icons/fa';


 const Navbar = ({toggleSide}) => {
 

  return (
    <>
   <FaBars onClick={toggleSide} id='bars'/>
    <nav id='navbar'>
    <NavLink to='/' className="navlink">Home</NavLink>  
    <NavLink to='about' className="navlink">About</NavLink>  
    <NavLink to='services' className="navlink">Services</NavLink>  
    <NavLink to='contact' className="navlink">Contact</NavLink>  
    </nav>
    </>
  )
}

export default Navbar