import React from 'react'
import landing from '../images/landing.jpg';
import bullet from '../images/bullet.png';
import logo from '../images/micrologo.png';
const Home = () => {
  return (
    <>
    <main className="main-home">
      <section className='landing'>
        <img id='landing-img' src={landing} alt="" />
        <div id="landing-about">
         <p><span id='name'>MICRO CRO </span><br /> is a small, private, independent CRO offering services of unmatched value to any organization seeking a cost-effective solution without sacrificing excellence of  services. <br />
         <p id='quote'> “The smaller the operation, the fewer moving parts”
          </p>
          <br />
          We have advantage of site speed, meticulous recruitment and short timelines for initial approvals with Regulatory Authorities. 
          </p>
        </div>
      </section>

      <section className="section-2">
        <div id="box-1">
          <ul>
            <li ><img className='inline bullet' src={bullet} alt="" /><p className='inline'>  Site selection</p> </li>

            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>  Stand-alone feasibility</p> </li>

            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>  Negotiation</p> </li>
          </ul>
        </div>
        <div id="box-2">
          <ul>
            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>  Monitoring</p> </li>

            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>  Pharmacovigilance</p> </li>

            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>  Translations</p> </li>
          </ul>
        </div>
        <div id="box-3">
          <ul>
            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>   Comunication with Authoritiees</p> </li>
            <li><img className='inline bullet' src={bullet} alt="" /><p className='inline'>   Licencing import/export</p> </li>
            
          </ul>
        </div>
      </section>
    </main>
    </>
  )
}

export default Home