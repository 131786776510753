import React from 'react'
import us from '../images/us.jpg';
import cardiology from '../images/cardiology.png';
import nephrology from '../images/Nephrology.png';
import dentistry from '../images/Dentistry.png';
import gastroenterology from '../images/Gastroenterology.png';
import rheumatology from '../images/Rheumatology.png';
import neurology from '../images/Neurology.png';
import endocrinology from '../images/Endocrinology.png';
import psychiatry from '../images/Psychiatry.png';
import oncology from '../images/Oncology.png';
import epidemiology from '../images/Epidemiology-studies.png';

const About = () => {
  return (
    <main className='main-about'>
      <section id='section-about'>
        <img id='about-img' src={us} alt="" />
        <div id='about-head'>
          <h1 className='title'>About Us</h1>
          <div className="underline"></div>
        </div>
        <article>
        <p>MICRO CRO is a small sized private independent company offering unmatched value to any organization seeking a cost-effective solution without sacrificing excellent service.</p>

<p>As a smaller CROwe may not have the breadth of services of the larger CROs, but it gives us more focus and efficiencies in the services we offer.</p>

<p>Our strong performance as a small sized CROis evident in the category for speed of site and investigator recruitment as well as in short timelines for obtaining Regulatory Authority initial approvals.</p>

<p>MICRO's top attributes that pharmaceutical companies are looking for in a CRO are: 
        </p>
        <ul>
          <li>Therapeutic expertise</li>
          <li>Low cost</li>
          <li>CRA quality</li>
          <li>Upfront contingency planning-having backup plans to manage changing circumstances as a clinical trial progresses.</li>
        </ul>
        <p> 
        We have the knowledge, expertise and processes to offer smaller Sponsors with limited teams and budgets to conduct successful clinical research studies guiding them through every step of the process and address problems, questions and situations from startup to closeout.</p>

<p>A customized communication strategy is vital for the MICRO and sponsor relationship. We promote development of ongoing, personalized strategies that integrate every possible communication: avenue, telephone, email, fax, regular post mail communications – into a solid monitoring program that gives the sponsor 24/7/365 access to enrolment and compliance.</p>

<p>Our 16+ years experienced CRA (clinical research associate) team, as the primary execution representatives in the field and their approach to site engagement has an enormous impact on patient recruitment and data quality.
        </p>
      <h3>Therapeutic areas</h3>
       <div className="box">
          <div className="box-item">
            <img src={cardiology} alt="" />  
           <span class="text2">Cardiology</span>
          </div>
          <div className="box-item">
            <img src={nephrology} alt="" />  
           <span class="text2">Nephrology</span>
          </div>
          <div className="box-item">
            <img src={dentistry} alt="" />  
           <span class="text2">Dentistry</span>
          </div>
          <div className="box-item">
            <img src={gastroenterology} alt="" />  
           <span class="text2">Gastroenterology</span>
          </div>
          <div className="box-item">
            <img src={rheumatology} alt="" />  
           <span class="text2">Rheumatology</span>
          </div>
          <div className="box-item">
            <img src={epidemiology} alt="" />  
           <span class="text2">Epidemiology</span>
          </div>
          <div className="box-item">
            <img src={oncology} alt="" />  
           <span class="text2">Oncology</span>
          </div>
          <div className="box-item">
            <img src={psychiatry} alt="" />  
           <span class="text2">Psychiatry</span>
          </div>
          <div className="box-item">
            <img src={neurology} alt="" />  
           <span class="text2">Neurology</span>
          </div>
          <div className="box-item">
            <img src={endocrinology} alt="" />  
           <span class="text2">Endocrinology</span>
          </div>
       </div>
       </article>
       </section>
    </main>
  )
}

export default About